<template>
  <main-layout>

    <template v-slot:breadcrumb>
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><a href="/">Home</a></a-breadcrumb-item>
        <a-breadcrumb-item :class="'active'">Quản lý đơn đặt hàng</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <a-form-model
      ref="ruleFilter"
      :model="filters"
      layout="vertical">
      <a-collapse v-model="activeSearchKey" expandIconPosition="left" class="collapse-left">
        <a-collapse-panel header="Điều kiện tìm kiếm" key="1">
          <a-card style="width: 100%;border: none" class="search-container">
            <a-row :gutter="16" type="flex" justify="center">
              <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                <a-form-model :model="filters" ref="ruleFilter">
                  <a-form-model-item
                    label="Trạng thái"
                    prop="status"
                    :rules="[]">
                    <a-select
                      v-model="filters.status"
                      :allowClear="true"
                      show-search
                      :filter-select-option="filterSelectOption">
                      <a-select-option :key="''" :value="''">--Tất cả--</a-select-option>
                      <a-select-option v-for="item in listStatus" :key="item.value" :value="item.value">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-form-model>
              </a-col>
              <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                <a-form-model-item prop="fromDate" label="Từ ngày">
                  <a-date-picker
                    placeholder="DD/MM/YYYY"
                    :format="'DD/MM/YYYY'"
                    @change="changeFromDate"
                    v-model="filters.fromDate"/>
                </a-form-model-item>
              </a-col>
              <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                <a-form-model-item prop="toDate" label="Đến ngày">
                  <a-date-picker
                    placeholder="DD/MM/YYYY"
                    :format="'DD/MM/YYYY'"
                    :disabled-date="disabledToDate"
                    v-model="filters.toDate"/>
                </a-form-model-item>
              </a-col>
              <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                <a-form-model-item prop="keyword" label="Từ khóa">
                  <a-input v-model="filters.keyword"></a-input>
                </a-form-model-item>
              </a-col>

            </a-row>
            <a-row :gutter="16">
              <a-col
                :xs="24"
                :md="24"
                :lg="24"
                class="filter-item-container"
                style="display: flex;flex-wrap: wrap; margin-top: 17px; justify-content: center">
                <a-button v-if="$auth.hasPrivilege('PRE_ORDER_MANAGEMENT_SEARCH')" type="primary" class="btn-success uppercase" @click="search">Tìm kiếm
                </a-button>
                <a-button class="btn-success uppercase" @click="resetForm" style="margin-left: 10px">
                  Nhập lại
                </a-button>
                <a-button v-if="$auth.hasPrivilege('PRE_ORDER_MANAGEMENT_INIT')" type="primary" class="btn-success uppercase" @click="showPopupImport" style="margin-left: 10px">Import
                </a-button>
              </a-col>
            </a-row>
          </a-card>
        </a-collapse-panel>
      </a-collapse>
    </a-form-model>
    <a-collapse v-model="activeResultKey" expandIconPosition="left" style=" margin-top: 8px" class="collapse-left">
      <a-collapse-panel header="Kết quả trả về" key="1">
        <a-card style="width: 100%; border: none" class="vts-table-container">
          <a-row :gutter="16" type="flex">
            <a-col :span="24">
              <div class="wrapper1">
                <div class="div1"></div>
              </div>
              <a-table
                :columns="columns"
                :data-source="data"
                :rowKey=" (rowKey, index ) => index"
                :pagination="data.length === 0 ? false : pagination"
                :loading="loading"
                :scroll="{ x: '100%' }"
                :locale="{ emptyText: 'Chưa có dữ liệu' }"
                @change="handleTableChange"
                @expand="expandTable"
                class="ant-table-bordered">
                <template slot="rowIndex" slot-scope="text, record, index">
                  <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                </template>
                <template slot="actionTitle" >
                  <a-icon type="control"></a-icon>
                </template>
                <template slot="parentNo" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.parentNo }}</span>
                    </template>
                    <span>{{ record.parentNo }}</span>
                  </a-tooltip>
                </template>
                <template slot="no" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.no }}</span>
                    </template>
                    <span>{{ record.no }}</span>
                  </a-tooltip>
                </template>
                <template slot="receiveAddress" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.receiveAddress }}</span>
                    </template>
                    <span>{{ record.receiveAddress }}</span>
                  </a-tooltip>
                </template>
                <template slot="createAt" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.createAt }}</span>
                    </template>
                    <span>{{ record.createAt }}</span>
                  </a-tooltip>
                </template>
                <template slot="completeAt" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.completeAt }}</span>
                    </template>
                    <span>{{ record.completeAt }}</span>
                  </a-tooltip>
                </template>
                <template slot="statusName" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.statusName }}</span>
                    </template>
                    <span>{{ record.statusName }}</span>
                  </a-tooltip>
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-popover >
                    <template slot="content" >
                      <span>Chi tiết</span>
                    </template>
                    <a-icon v-if="$auth.hasPrivilege('PRE_ORDER_MANAGEMENT_DETAIL')" type="eye" @click="goToDetail(record)" style=" color: #086885"></a-icon>
                  </a-popover>
                  <a-popover >
                    <template slot="content" >
                      <span>Xóa</span>
                    </template>
                    <a-icon v-if="$auth.hasPrivilege('PRE_ORDER_MANAGEMENT_DELETE') && Number(record.status) !== 4" type="delete" @click="deleteRecord(record)" style=" color: #086885; margin-left: 8px"></a-icon>
                  </a-popover>
                </template>
                <a-table
                  slot="expandedRowRender"
                  slot-scope="record"
                  :columns="columnsChild"
                  :data-source="record.listChild"
                  :pagination="false"
                >
                  <template slot="rowIndex" slot-scope="text, recordC, index">
                    <span>{{ index+1 }} </span>
                  </template>
                </a-table>
              </a-table>
            </a-col>
          </a-row>
        </a-card>
      </a-collapse-panel>
    </a-collapse>
    <popup-import-order
      v-if="visibleImport === true"
      :visibleImport="visibleImport"
      @closePopup="closePopup"></popup-import-order>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import resizeableTitle from '@/utils/resizable-columns'
import TableEmptyText from '@/utils/table-empty-text'
import columns from './columns'
import _merge from 'lodash/merge'
import { searchPreOrder, getListVoucher, deleteOrder } from '@/api/pre-order'
import { commonMethods, authComputed } from '@/store/helpers'
import pdf from 'vue-pdf'
import columnsChild from './columnChild'
import moment from 'moment'
import PopupImportOrder from './PopupImportOrder'

const ResizeableTitle = resizeableTitle(columns)
export default {
  components: {
    MainLayout,
    pdf,
    PopupImportOrder
  },
  mixins: [TableEmptyText],
  name: 'WarehouseManagement',
  data () {
    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
    return {
      moment,
      activeSearchKey: 1,
      activeResultKey: 1,
      data: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      },
      loading: false,
      columns,
      columnsChild,
      loadingExport: false,
      filters: {
        status: '',
        fromDate: undefined,
        toDate: undefined,
        keyword: ''
      },
      listStatus: [],
      visibleImport: false
    }
  },
  created () {
    this.getListStatus()
    this.getData()
  },
  mounted () {
    this.scrollBarOfTable()
  },
  computed: {
    ...authComputed
  },
  methods: {
    ...commonMethods,
    changeFromDate (value) {
      if (value) {
        this.filters.toDate = ''
      }
    },
    disabledToDate (toDate) {
      const fromDate = this.filters.fromDate
      if (!toDate || !fromDate) {
        return false
      }
      return fromDate.valueOf() >= toDate.valueOf()
    },
    getListStatus () {
      this.listStatus = [
        {
          value: '1',
          name: 'Tạo đơn'
        },
        {
          value: '2',
          name: 'Đang vận chuyển'
        },
        {
          value: '3',
          name: 'Hoàn thành'
        },
        {
          value: '4',
          name: 'Đã xóa'
        }
      ]
    },
    resetForm (e) {
      this.$refs.ruleFilter.resetFields()
      this.search(e)
    },
    handleTableChange (pagination, filters, sorter) {
      this.pagination = pagination
      this.getData()
    },
    search () {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          this.pagination.current = 1
          this.getData()
        }
      })
    },
    getData () {
      const params = {
        page: this.pagination.current > 0 ? this.pagination.current - 1 : 0,
        size: this.pagination.pageSize,
        status: this.filters.status,
        keyword: this.filters.keyword,
        fromDate: moment.isMoment(this.filters.fromDate) ? moment(this.filters.fromDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
        toDate: moment.isMoment(this.filters.toDate) ? moment(this.filters.toDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : ''
      }
      this.loading = true
      this.data = []
      searchPreOrder(params).then(res => {
        res.data.map(item => {
          item.listChild = []
          return item
        })
        this.data = this.convertPropToDisplayDate(res.data)
        this.pagination = _merge(this.pagination, this.handlePaginationData(res))
        this.loading = false
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$notification.error({
          message: '',
          description: msg,
          duration: 5
        })
      }).finally(res => {
        this.loading = false
      })
    },
    expandTable (expanded, record) {
      if (expanded === true) {
        this.getListDataChild(record)
      }
    },
    getListDataChild (record) {
      getListVoucher(record.id).then(rs => {
        if (rs) {
          record.listChild = rs
          this.loading = false
        }
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$notification.error({
          message: '',
          description: msg,
          duration: 5
        })
      }).finally(res => {
        this.loading = false
      })
    },
    goToDetail (record) {
      this.$router.push({ name: 'pre_order_management.detail', params: { id: record.id } })
    },
    showPopupImport () {
      this.visibleImport = true
    },
    closePopup () {
      this.visibleImport = false
      this.getData()
    },
    deleteRecord (record) {
      this.$confirm({
        title: 'Bạn muốn xóa đơn đặt hàng này?',
        okText: 'Có',
        okType: 'primary',
        cancelText: 'Không',
        onOk: () => {
          if (record.id) {
            this.deleteGL(record.id)
          }
        },
        onCancel () {
        }
      })
    },
    deleteGL (id) {
      const $this = this
      this.loading = true
      deleteOrder(id)
        .then(rs => {
          $this.getData()
          this.$success({ content: 'Xóa đơn đặt hàng thành công'
          })
        })
        .catch(err => {
          const msg = this.handleApiError(err)
          this.$notification.error({
            message: '',
            description: msg,
            duration: 5
          })
        }).finally(res => {
          this.loading = false
        })
    }
  }
}
</script>
