import { axios } from '@/utils/request'
import api from '@/api/index'

export function searchSummaryReport (parameter) {
  return axios({
    url: api.report.summary.search,
    method: 'get',
    params: parameter
  })
}

export function exportSummaryReport (parameter) {
  return axios({
    url: api.report.export,
    method: 'post',
    data: parameter,
    responseType: 'blob'
  })
}
