import { axios } from '@/utils/request'
import api from '@/api/index'

export function searchImportReport (parameter) {
  return axios({
    url: api.report.import.search,
    method: 'get',
    params: parameter
  })
}

export function detailImportReport (parameter) {
  return axios({
    url: api.report.import.detail,
    method: 'get',
    params: parameter
  })
}

export function exportImportReport (parameter) {
  return axios({
    url: api.report.export,
    method: 'post',
    data: parameter,
    responseType: 'blob'
  })
}
