<template>
  <a-modal
    :visible="visible"
    :title="'Chi tiết nhập hàng của xe'"
    @cancel="closePopup"
    width="75%"
    :maskClosable="false"
    :destroyOnClose="false"
    :footer="false"
  >
    <a-spin :spinning="loading">
      <a-card style="border: none; padding: 0px 10px">
        <a-form-model :model="form" ref="ruleForm">
          <a-row :gutter="16">
            <a-col :xs="24" :md="24" :lg="24">
              <a-row :gutter="16" type="flex">
                <a-col :span="24">
                  <a-table
                    ref="tb1"
                    :columns="columnsDetail"
                    :data-source="data"
                    :rowKey=" (rowKey, index ) => index"
                    :pagination="data.length === 0 ? false : pagination"
                    :scroll="{ x: '100%' }"
                    :locale="{ emptyText: 'Chưa có dữ liệu' }"
                    @change="handleTableChange"
                    class="ant-table-bordered">
                    <template slot="actionTitle">
                      <a-icon type="control" :style="{fontSize: '14px'}"/>
                    </template>
                    <template slot="rowIndex" slot-scope="text, record, index">
                      <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                    </template>
                  </a-table>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :xs="24" :md="24" :lg="24">
              <div style="display: flex; justify-content: center; margin-top: 10px">
                <a-button type="primary" :loading="loading" @click="exportReport('xlsx')">
                  Xuất Excel
                </a-button>
              </div>
            </a-col>
          </a-row>
        </a-form-model>
      </a-card>
    </a-spin>
  </a-modal>
</template>
<script>

import columnsDetail from './columnsDetail'
import { detailImportByPlateReport, exportImportByPlateReport } from '@/api/Report/import-by-plate'
import _merge from 'lodash/merge'
import moment from 'moment/moment'

export default {
  props: {
    visibleDetail: {
      type: Boolean,
      required: true,
      default: false
    },
    listId: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      columnsDetail,
      form: {

      },
      data: [],
      expandedRowKeys: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      },
      loading: false
    }
  },
  mounted () {
    if (this.visibleDetail === true) {
      this.visible = true
    } else {
      this.visible = false
    }
    if (this.listId) {
      this.findById()
    } else {
      this.form = {}
    }
  },
  methods: {
    closePopup () {
      this.visible = false
      this.$emit('closePopup')
    },
    findById () {
      const params = {
        voucherId: '<' + this.listId.join('><') + '>'
      }
      this.loading = true
      detailImportByPlateReport(params).then(rs => {
        if (rs) {
          this.data = this.convertPropToDisplayDate(rs)
          this.pagination = _merge(this.pagination, this.handlePaginationData(rs))
        }
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$notification.error({
          message: msg,
          duration: 5
        })
      }).finally(res => {
        this.loading = false
      })
    },
    handleTableChange (pagination, filters, sorter) {
      this.pagination = pagination
    },

    // Xuất excel
    exportReport (type) {
      const params = {
        reportName: 'rp_import_by_plate_detail',
        fileType: type,
        fileName: 'Danh_sach_kien_hang_da_nhap',
        params: {
          voucherId: '<' + this.listId.join('><') + '>'
        }
      }
      this.loading = true
      exportImportByPlateReport(params)
        .then(rs => {
          const date = new Date(moment())
          const fileName = 'Danh_sach_kien_hang_da_nhap_' + moment(date, 'YYYY_MM_DD').format('YYYY_MM_DD') + '.' + type
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(rs, fileName)
          } else {
            const downloadLink = window.document.createElement('a')
            downloadLink.href = window.URL.createObjectURL(rs)
            downloadLink.download = fileName
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          }
        }).catch(err => {
          const msg = this.handleApiError(err)
          this.$error({ content: msg })
        }).finally(res => {
          this.loading = false
        })
    }
  }
}
</script>
