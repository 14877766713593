<template>
  <home-layout>
    <template slot="breadcrumb">

    </template>
  </home-layout>
</template>
<script>
import HomeLayout from '@/pages/layouts/HomeLayout'

export default {
  components: {
    HomeLayout
  }
}
</script>
