import { axios } from '@/utils/request'
import api from '@/api/index'

export function searchImportExportManagement (parameter) {
  return axios({
    url: api.importExportManagement.search,
    method: 'get',
    params: parameter
  })
}

export function getByIdImportExportManagement (parameter) {
  return axios({
    url: api.importExportManagement.getById,
    method: 'get',
    params: parameter
  })
}

export function updateImportExportManagement (parameter) {
  return axios({
    url: api.importExportManagement.update,
    method: 'post',
    data: parameter
  })
}

// Kiểm tra phiếu yêu cầu
export function checkPrintVoucherImportExportManagement (parameter) {
  return axios({
    url: api.importExportManagement.checkPrintVoucher,
    method: 'get',
    params: parameter
  })
}

// Xác nhận xuất kho
export function acceptExportImportExportManagement (parameter) {
  return axios({
    url: api.importExportManagement.acceptExport,
    method: 'post',
    data: parameter
  })
}

// Xác nhận giao hàng thành công
export function acceptSuccessfulDeliveryImportExportManagement (parameter) {
  return axios({
    url: api.importExportManagement.acceptSuccessfulDelivery,
    method: 'post',
    data: parameter
  })
}

// Xóa kiện hàng trong đơn hàng
export function deleteVoucherDetail (id) {
  return axios({
    url: '/voucher-detail/admin/' + id + '/delete',
    method: 'post'
  })
}

// Xóa tài liệu trong đơn hàng
export function deleteDocumentVoucherDetail (id) {
  return axios({
    url: '/document/admin/' + id + '/delete',
    method: 'post'
  })
}

// In phiếu xuất
export function printImportExportManagement (parameter) {
  return axios({
    url: api.importExportManagement.print,
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}
