export default [
  {
    title: 'STT',
    dataIndex: 'rowIndex',
    scopedSlots: { customRender: 'rowIndex' },
    align: 'center',
    width: 50
  },
  {
    title: 'Mã đơn đặt hàng',
    dataIndex: 'preOrderNo',
    scopedSlots: { customRender: 'preOrderNo' },
    align: 'left',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Biển số xe',
    dataIndex: 'plateNumber',
    scopedSlots: { customRender: 'plateNumber' },
    align: 'left',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Mã kiện hàng',
    dataIndex: 'cartonNo',
    scopedSlots: { customRender: 'cartonNo' },
    align: 'left',
    ellipsis: true,
    width: 200
  },
  {
    title: 'Tên hàng',
    dataIndex: 'descOfGoods',
    scopedSlots: { customRender: 'descOfGoods' },
    align: 'right',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Mã ID',
    dataIndex: 'shopeePoId',
    scopedSlots: { customRender: 'shopeePoId' },
    align: 'right',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Thời gian nhập kho',
    dataIndex: 'importAt',
    scopedSlots: { customRender: 'importAt' },
    align: 'center',
    ellipsis: true,
    width: 120
  },
  {
    title: 'Thời gian xuất kho',
    dataIndex: 'exportAt',
    scopedSlots: { customRender: 'exportAt' },
    align: 'center',
    ellipsis: true,
    width: 120
  }
]
