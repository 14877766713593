<template>
  <main-layout>

    <template v-slot:breadcrumb>
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><a href="/">Home</a></a-breadcrumb-item>
        <a-breadcrumb-item :class="'active'">Báo cáo nhập hàng</a-breadcrumb-item>
      </a-breadcrumb>

    </template>
    <a-form-model
      ref="ruleFilter"
      :model="filters"
      @submit="search"
      layout="vertical">
      <a-collapse v-model="activeSearchKey" expandIconPosition="left" class="collapse-left">
        <a-collapse-panel header="Điều kiện tìm kiếm" key="1">
          <a-card style="width: 100%;border: none" class="search-container">
            <a-row :gutter="16" type="flex" justify="center">
              <a-col :xs="6" :md="6" :lg="6" class="filter-item-container">
                <a-form-model-item prop="warehouseId" label="Kho">
                  <a-select
                    :filter-option="filterSelectOption"
                    placeholder="-- Chọn kho hàng --"
                    :allowClear="true"
                    mode="multiple"
                    show-search
                    style="width: 100%"
                    :disabled="disabledWarehouse"
                    v-model="filters.warehouseId"
                  >
                    <a-select-option
                      v-for="item in listWarehouse"
                      :key="'t-p-' + item.id"
                      :value="item.id">{{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :xs="6" :md="6" :lg="6" class="filter-item-container">
                <a-form-model-item prop="fromDate" label="Từ ngày">
                  <a-date-picker
                    placeholder="DD/MM/YYYY"
                    :format="'DD/MM/YYYY'"
                    v-model="filters.fromDate"
                    :disabled-date="disabledFromDate">

                  </a-date-picker>
                </a-form-model-item>
              </a-col>
              <a-col :xs="6" :md="6" :lg="6" class="filter-item-container">
                <a-form-model-item prop="toDate" label="Đến ngày">
                  <a-date-picker
                    placeholder="DD/MM/YYYY"
                    :format="'DD/MM/YYYY'"
                    v-model="filters.toDate"
                    :disabled-date="disabledToDate">
                  </a-date-picker>
                </a-form-model-item>
              </a-col>
              <a-col :xs="6" :md="6" :lg="6" class="filter-item-container">
                <a-form-model-item prop="keyword" label="Từ khóa">
                  <a-input
                    placeholder="Mã đơn hàng, Mã  kiện hàng"
                    v-model="filters.keyword"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col
                :xs="24"
                :md="24"
                :lg="24"
                class="filter-item-container"
                style="display: flex;flex-wrap: wrap; margin-top: 20px; justify-content: center">
                <a-button class="btn-success uppercase" @click="resetForm" style="margin-left: 10px">
                  Nhập lại
                </a-button>
                <a-button
                  style="margin-left: 10px"
                  :loading="loading"
                  :disabled="loadingPdf || loadingExport"
                  type="primary"
                  class="btn-success uppercase"
                  @click="search">Tìm kiếm
                </a-button>
                <a-button
                  :loading="loadingExport"
                  :disabled="loadingPdf || loading"
                  type="primary"
                  class="btn-success uppercase"
                  style="margin-left: 10px"
                  @click="exportExcel('xlsx')">Xuất Excel
                </a-button>
              </a-col>
            </a-row>
          </a-card>
        </a-collapse-panel>
      </a-collapse>
    </a-form-model>
    <a-collapse v-model="activeResultKey" expandIconPosition="left" style=" margin-top: 8px" class="collapse-left">
      <a-collapse-panel header="Kết quả tìm kiếm" key="1">
        <a-card style="width: 100%; border: none" class="vts-table-container">
          <a-row :gutter="16" type="flex">
            <a-col :span="24">
              <div class="wrapper1">
                <div class="div1"></div>
              </div>
              <a-table
                ref="tb1"
                :columns="columns"
                :data-source="data"
                :rowKey=" (rowKey, index ) => index"
                :pagination="data.length === 0 ? false : pagination"
                :loading="loading"
                :scroll="{ x: '100%' }"
                :locale="{ emptyText: 'Chưa có dữ liệu' }"
                @change="handleTableChange"
                class="ant-table-bordered">
                <template slot="actionTitle">
                  <a-icon type="control" :style="{fontSize: '14px'}"/>
                </template>
                <template slot="rowIndex" slot-scope="text, record, index">
                  <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                </template>
                <template slot="totalCartonNo" slot-scope="text, record">
                  <span>{{ formatPriceNotZero(record.totalCartonNo) }} </span>
                </template>
                <template slot="notYetImport" slot-scope="text, record">
                  <span>{{ formatPriceNotZero(record.notYetImport) }} </span>
                </template>
                <template slot="imported" slot-scope="text, record">
                  <span>{{ formatPriceNotZero(record.imported) }} </span>
                </template>
                <template slot="exported" slot-scope="text, record">
                  <span>{{ formatPriceNotZero(record.exported) }} </span>
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-popover >
                    <template slot="content" >
                      <span>Chi tiết</span>
                    </template>
                    <a-icon type="eye" @click="showDetail(record)" style=" color: #086885"></a-icon>
                  </a-popover>
                </template>
              </a-table>
            </a-col>
          </a-row>
        </a-card>
      </a-collapse-panel>
    </a-collapse>
    <popup-detail
      v-if="visibleDetail === true"
      :visibleDetail="visibleDetail"
      :modelObject="modelObject"
      @closePopup="closeDetail">

    </popup-detail>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import resizeableTitle from '@/utils/resizable-columns'
import TableEmptyText from '@/utils/table-empty-text'
import columns from './columns'
import _merge from 'lodash/merge'
import _ from 'lodash'
import { exportImportReport, searchImportReport } from '@/api/Report/import'
import { commonMethods, authComputed } from '@/store/helpers'
import moment from 'moment'
import pdf from 'vue-pdf'
import { searchWarehouseManagement } from '@/api/warehouse-management'
import PopupDetail from './PopupDetail'

const ResizeableTitle = resizeableTitle(columns)
export default {
  components: {
    MainLayout,
    pdf,
    PopupDetail
  },
  mixins: [TableEmptyText],
  name: 'ImportReport',
  data () {
    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
    return {
      activeSearchKey: 1,
      activeResultKey: 1,
      data: [],
      expandedRowKeys: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      },
      loading: false,
      columns,
      filters: {
        page: '',
        size: '',
        warehouseId: undefined,
        fromDate: moment().startOf('month'),
        toDate: moment().endOf('month'),
        keyword: ''
      },
      listWarehouse: [],
      dataPdf: '',
      loadingExport: false,
      loadingPdf: false,
      disabledWarehouse: false,
      store: JSON.parse(window.localStorage.getItem('store')) || null,
      visibleDetail: false,
      modelObject: {}
    }
  },
  async created () {
    await this.getListWarehouse()
  },
  mounted () {
    this.scrollBarOfTable()
    if (this.store && this.store !== 'All') {
      this.filters.warehouseId = [this.store.id]
      this.disabledWarehouse = true
      // this.getData()
    } else {
      this.filters.warehouseId = undefined
      this.disabledWarehouse = false
      // this.getData()
    }
  },
  computed: {
    ...authComputed
    // oldfilter () {
    //   const filter = window.localStorage.getItem('rp_import.filters')
    //   return JSON.parse(filter)
    // }
  },
  methods: {
    ...commonMethods,
    disabledFromDate (fromDate) {
      const toDate = this.filters.toDate
      if (!fromDate || !toDate) {
        return false
      }
      return toDate.valueOf() <= fromDate.valueOf()
    },
    disabledToDate (toDate) {
      const fromDate = this.filters.fromDate
      if (!toDate || !fromDate) {
        return false
      }
      return fromDate.valueOf() >= toDate.valueOf()
    },
    getListWarehouse () {
      searchWarehouseManagement({}).then(rs => {
        if (rs) {
          this.listWarehouse = rs.data
        }
      })
    },
    resetForm (e) {
      this.$refs.ruleFilter.resetFields()
      this.search(e)
    },
    handleTableChange (pagination, filters, sorter) {
      this.pagination = pagination
      this.getData()
    },
    search () {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          this.pagination.current = 1
          this.getData()
        }
      })
    },
    getData () {
      this.loading = true
      let listWarehouse = ''
      if (this.filters.warehouseId !== undefined) {
        listWarehouse = '<' + this.filters.warehouseId.join('><') + '>'
      } else {
        const listIdWarehouse = []
        this.listWarehouse.forEach(item => {
          listIdWarehouse.push(item.id)
        })
        listWarehouse = '<' + listIdWarehouse.join('><') + '>'
      }
      let params = {}
      if (listWarehouse !== '' && listWarehouse !== '<>') {
        params = {
          page: this.filters.page,
          size: this.filters.size,
          fromDate: this.filters.fromDate !== null ? moment(this.filters.fromDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
          toDate: this.filters.toDate !== null ? moment(this.filters.toDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
          keyword: this.filters.keyword,
          listWarehouseId: listWarehouse
        }
      }
      this.data = []
      searchImportReport(params).then(res => {
        this.data = this.convertPropToDisplayDate(res)
        this.data = this.data.map(item => {
          return _merge(item, { status: item.status === '1' })
        })
        this.pagination = _merge(this.pagination, this.handlePaginationData(res))
        window.localStorage.setItem('rp_import.filters', JSON.stringify(params))
        this.loading = false
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$notification.error({
          message: '',
          description: msg,
          duration: 5
        })
      }).finally(res => {
        this.loading = false
      })
    },
    showDetail (record) {
      this.visibleDetail = true
      this.modelObject = _.cloneDeep(record)
    },
    closeDetail () {
      this.visibleDetail = false
      this.modelObject = {}
    },
    exportExcel (type) {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          let warehouseName = ''
          let listWarehouseId = ''
          if (this.filters.warehouseId !== undefined) {
            const listWarehouseName = []
            this.filters.warehouseId.forEach(item => {
              this.listWarehouse.forEach(itemC => {
                if (item === itemC.id) {
                  listWarehouseName.push(itemC.name)
                }
              })
            })
            warehouseName = listWarehouseName.join(',')
            listWarehouseId = '<' + this.filters.warehouseId.join('><') + '>'
          } else {
            const listIdWarehouse = []
            const listIdWarehouseName = []
            this.listWarehouse.forEach(item => {
              listIdWarehouse.push(item.id)
              listIdWarehouseName.push(item.name)
            })
            listWarehouseId = '<' + listIdWarehouse.join('><') + '>'
            warehouseName = listIdWarehouseName.join(',')
          }
          const params = {
            fileType: type,
            fileName: 'Bao_cao_nhap_hang',
            params: {
              listWarehouseId: listWarehouseId,
              listWarehouseName: warehouseName,
              fromDate: this.filters.fromDate !== null ? moment(this.filters.fromDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
              toDate: this.filters.toDate !== null ? moment(this.filters.toDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : '',
              keyword: this.filters.keyword
            },
            reportName: 'rp_import'
          }
          this.loadingExport = true
          exportImportReport(params)
            .then(rs => {
              const date = new Date(moment())
              const fileName = 'Bao_cao_nhap_hang_' + moment(date, 'YYYY_MM_DD').format('YYYY_MM_DD') + '.' + type
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(rs, fileName)
              } else {
                const downloadLink = window.document.createElement('a')
                downloadLink.href = window.URL.createObjectURL(rs)
                downloadLink.download = fileName
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
              }
            }).catch(err => {
              const msg = this.handleApiError(err)
              this.$error({ content: msg })
            }).finally(res => {
              this.loadingExport = false
            })
        }
      })
    }

  }
}
</script>
