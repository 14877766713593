<template>
  <main-layout>

    <template v-slot:breadcrumb>
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><a href="/">Home</a></a-breadcrumb-item>
        <a-breadcrumb-item><a href="/voucher-management">Nhập xuất hàng</a></a-breadcrumb-item>
        <a-breadcrumb-item :class="'active'">Cập nhật</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <a-form-model
      ref="ruleForm"
      :model="form"
      layout="vertical">
      <a-collapse v-model="activeKey" expandIconPosition="left" class="collapse-left">
        <a-collapse-panel header="Thông tin chi tiết" key="1">
          <a-spin :spinning="loading">
            <a-card style="width: 100%;border: none" class="search-container">
              <a-row :gutter="16">
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Tên kho"
                    prop="warehouseName"
                    :rules="[]">
                    <a-input
                      :disabled="true"
                      v-model="form.warehouseName">
                    </a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Mã Phiếu"
                    prop="voucherCode"
                    :rules="[]">
                    <a-input
                      :disabled="true"
                      v-model="form.voucherCode">
                    </a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Mã đơn hàng"
                    prop="preOrderNo"
                    :rules="[]">
                    <a-input
                      :disabled="true"
                      v-model="form.preOrderNo">
                    </a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Trạng thái"
                    prop="statusName"
                    :rules="[]">
                    <a-input
                      :disabled="true"
                      v-model="form.statusName">
                    </a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="16" type="flex" justify="center">
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Thời gian nhập hàng"
                    prop="importAt"
                    :rules="[
                      {
                        required: true,
                        message: 'Thời gian nhập hàng bắt buộc chọn',
                        trigger: 'change'
                      }
                    ]">
                    <a-date-picker
                      show-time
                      allowClear
                      placeholder="DD/MM/YYYY HH:MM"
                      :format="'DD/MM/YYYY HH:mm'"
                      v-model="form.importAt">
                    </a-date-picker>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Thời gian nhập hàng xong"
                    prop="exportAt"
                    :rules="[]">
                    <a-date-picker
                      show-time
                      allowClear
                      placeholder="DD/MM/YYYY HH:MM"
                      :format="'DD/MM/YYYY HH:mm'"
                      v-model="form.exportAt">
                    </a-date-picker>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="12" :lg="12" class="filter-item-container">
                  <a-form-model-item
                    label="Kho đích cuối"
                    prop="receiveAddress"
                    :rules="[]">
                    <a-input
                      :disabled="true"
                      v-model="form.receiveAddress">
                    </a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Thời gian đến SPX"
                    prop="spxArrivalTime"
                    :rules="[]">
                    <a-date-picker
                      show-time
                      allowClear
                      placeholder="DD/MM/YYYY HH:MM"
                      :format="'DD/MM/YYYY HH:mm'"
                      v-model="form.spxArrivalTime">
                    </a-date-picker>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Thời gian SPX bắt đầu nhận"
                    prop="spxStartReceiveTime"
                    :rules="[]">
                    <a-date-picker
                      show-time
                      allowClear
                      placeholder="DD/MM/YYYY HH:MM"
                      :format="'DD/MM/YYYY HH:mm'"
                      v-model="form.spxStartReceiveTime">
                    </a-date-picker>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container">
                  <a-form-model-item
                    label="Thời gian SPX ký nhận"
                    prop="deliveredAt"
                    :rules="[]">
                    <a-date-picker
                      show-time
                      allowClear
                      placeholder="DD/MM/YYYY HH:MM"
                      :format="'DD/MM/YYYY HH:mm'"
                      v-model="form.deliveredAt">
                    </a-date-picker>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :md="6" :lg="6" class="filter-item-container"></a-col>
              </a-row>
              <a-row :gutter="16">
                <a-col :xs="24" :md="24" :lg="24">
                  <div style="display: flex; justify-content: center">
                    <a-button style="margin-right: 1rem" @click="goToBack">Quay lại</a-button>
                    <a-button style="margin-right: 1rem" @click="updateVoucher">Cập nhật</a-button>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-spin>
        </a-collapse-panel>
      </a-collapse>
    </a-form-model>

    <a-collapse v-model="activeKey" expandIconPosition="left" style=" margin-top: 8px" class="collapse-left">
      <a-collapse-panel header="Danh sách kiện hàng và tài liệu đính kèm" key="1">
        <a-card style="width: 100%; border: none" class="vts-table-container">
          <a-row :gutter="16" type="flex">
            <a-col :span="13">
              <a-table
                ref="tb1"
                :columns="columns"
                :data-source="data"
                :rowKey=" (rowKey, index ) => index"
                :pagination="pagination"
                :loading="loading"
                :scroll="{ x: '100%' }"
                :locale="{ emptyText: 'Chưa có dữ liệu' }"
                @change="handleTableChange"
                class="ant-table-bordered">
                <template slot="rowIndex" slot-scope="text, record, index">
                  <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                </template>
                <template slot="actionTitle" >
                  <a-icon type="control"></a-icon>
                </template>
                <template slot="shopeePoId" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.shopeePoId }}</span>
                    </template>
                    <span>{{ record.shopeePoId }}</span>
                  </a-tooltip>
                </template>
                <template slot="cartonNo" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.cartonNo }}</span>
                    </template>
                    <span>{{ record.cartonNo }}</span>
                  </a-tooltip>
                </template>
                <template slot="createAt" slot-scope="text, record">
                  <a-tooltip placement="bottomLeft">
                    <template slot="title">
                      <span>{{ record.createAt }}</span>
                    </template>
                    <span>{{ record.createAt }}</span>
                  </a-tooltip>
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-popover >
                    <template slot="content" >
                      <span>Xóa</span>
                    </template>
                    <a-icon v-if="$auth.hasPrivilege('VOUCHER_DETAIL_DELETE')" type="delete" @click="deleteRecord(record)" style=" color: red;"></a-icon>
                  </a-popover>
                </template>
              </a-table>
            </a-col>
            <a-col :span="9">
              <a-table
                ref="tb1"
                :columns="columnsDocument"
                :data-source="dataDocument"
                :rowKey=" (rowKey, index ) => index"
                :pagination="paginationDocument"
                :loading="loading"
                :scroll="{ x: '100%' }"
                :locale="{ emptyText: 'Chưa có dữ liệu' }"
                @change="handleTableChangeDocument"
                class="ant-table-bordered">
                <template slot="rowIndex" slot-scope="text, record, index">
                  <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                </template>
                <template slot="fileName" slot-scope="text, record">
                  <a @click="downloadFile(record)"> {{ record.fileName }}</a>
                </template>
                <template slot="actionTitle" >
                  <a-icon type="control"></a-icon>
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-popover >
                    <template slot="content" >
                      <span>Xóa</span>
                    </template>
                    <a-icon v-if="$auth.hasPrivilege('DOCUMENT_DELETE')" type="delete" @click="deleteRecordDocument(record)" style=" color: red;"></a-icon>
                  </a-popover>
                </template>
              </a-table>
            </a-col>
          </a-row>
        </a-card>
      </a-collapse-panel>
    </a-collapse>
    <!--    <popup-accept-export v-if="visibleAcceptExport === true" :visibleAcceptExport="visibleAcceptExport" @closePopup="closePopup"></popup-accept-export>-->
    <!--    <popup-accept-successfully-delivery v-if="visibleAcceptDelivery === true" :visibleAcceptDelivery="visibleAcceptDelivery" @closePopup="closeAcceptDelivery"></popup-accept-successfully-delivery>-->
  </main-layout>
</template>
<script>
import MainLayout from '@/pages/layouts/MainLayout'
import {
  deleteDocumentVoucherDetail,
  deleteVoucherDetail,
  getByIdImportExportManagement,
  printImportExportManagement,
  updateImportExportManagement
} from '@/api/import-export-management'
import moment from 'moment'
import columns from './columnsDetail'
import columnsDocument from './columnsDocument'
import PopupAcceptExport from './PopupAcceptExport'
import PopupAcceptSuccessfullyDelivery from './PopupAcceptSuccessfullyDelivery'
import _ from 'lodash'
import { getDetailFile } from '@/api/pre-order'

export default {
  components: {
    MainLayout,
    PopupAcceptExport,
    PopupAcceptSuccessfullyDelivery
  },
  data () {
    return {
      moment,
      columns,
      columnsDocument,
      activeKey: [1, 2, 3],
      form: {
        id: '',
        warehouseName: '',
        voucherCode: '',
        preOrderNo: '',
        statusName: '',
        importAt: '',
        exportAt: '',
        receiveAddress: '',
        spxArrivalTime: '',
        spxStartReceiveTime: '',
        deliveredAt: ''
      },
      data: [],
      dataDocument: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      },
      paginationDocument: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      },
      loading: false,
      visibleAcceptExport: false,
      visibleAcceptDelivery: false
    }
  },
  created () {
    this.getById()
  },
  methods: {
    getById () {
      this.loading = true
      getByIdImportExportManagement({ voucherId: this.$route.params.id }).then(rs => {
        if (rs) {
          this.form = {
            ...rs,
            importAt: rs.importAt ? moment(rs.importAt, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : '',
            exportAt: rs.exportAt ? moment(rs.exportAt, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : '',
            deliveredAt: rs.deliveredAt ? moment(rs.deliveredAt, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : '',
            spxArrivalTime: rs.spxArrivalTime ? moment(rs.spxArrivalTime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : '',
            spxStartReceiveTime: rs.spxStartReceiveTime ? moment(rs.spxStartReceiveTime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : ''
          }
          this.data = rs.listDetail
          this.dataDocument = rs.listDocument
          this.pagination = _.merge(this.pagination, this.handlePaginationData(rs.listDetail))
          this.paginationDocument = _.merge(this.paginationDocument, this.handlePaginationData(rs.listDocument))
        }
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$notification.error({
          message: 'Lỗi',
          description: msg,
          duration: 5
        })
      }).finally(res => {
        this.loading = false
      })
    },

    updateVoucher () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            id: this.form.id,
            warehouseName: this.form.warehouseName,
            voucherCode: this.form.voucherCode,
            preOrderNo: this.form.preOrderNo,
            statusName: this.form.statusName,
            importAt: this.form.importAt ? moment(this.form.importAt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '',
            exportAt: this.form.exportAt ? moment(this.form.exportAt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '',
            receiveAddress: this.form.receiveAddress,
            spxArrivalTime: this.form.spxArrivalTime ? moment(this.form.spxArrivalTime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '',
            spxStartReceiveTime: this.form.spxStartReceiveTime ? moment(this.form.spxStartReceiveTime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '',
            deliveredAt: this.form.deliveredAt ? moment(this.form.deliveredAt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : ''
          }
          updateImportExportManagement(params).then(rs => {
            if (rs) {
              this.$success({ content: 'Cập nhật thành công' })
            }
          }).catch(err => {
            const msg = this.handleApiError(err)
            this.$notification.error({
              message: '',
              description: msg,
              duration: 5
            })
          }).finally(res => {
            this.loading = false
          })
        }
      })
    },
    handleTableChange (pagination, filters, sorter) {
      this.pagination = pagination
    },
    handleTableChangeDocument (pagination, filters, sorter) {
      this.paginationDocument = pagination
    },
    // checkPrintVoucher () {
    //   const $this = this
    //   this.loading = true
    //   checkPrintVoucherImportExportManagement({ voucherId: this.$route.params.id }).then(rs => {
    //     if (rs === true) {
    //       this.loading = false
    //       this.$confirm({ content: 'Phiếu đã được in. Bạn có muốn tiếp tục in không?',
    //         okText: 'In',
    //         cancelText: 'Hủy',
    //         onOk () {
    //           $this.printVoucher()
    //         }
    //       })
    //     } else {
    //       $this.printVoucher()
    //       $this.loading = false
    //     }
    //   })
    // },
    printVoucher () {
      const params = {
        voucherId: this.$route.params.id,
        preOrderId: this.form.preOrderId
      }
      this.loading = true
      printImportExportManagement(params).then(rs => {
        const date = new Date(moment())
        const fileName = 'Phieu_xuat_' + moment(date, 'YYYY_MM_DD ') + '.xlsx'
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(rs, fileName)
        } else {
          const downloadLink = window.document.createElement('a')
          downloadLink.href = window.URL.createObjectURL(rs)
          downloadLink.download = fileName
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
        }
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$error({ content: msg })
      }).finally(res => {
        this.loading = false
      })
    },
    // showAcceptExport () {
    //   this.visibleAcceptExport = true
    // },
    // closePopup () {
    //   this.visibleAcceptExport = false
    //   this.getById()
    // },
    // showAcceptDelivery () {
    //   this.visibleAcceptDelivery = true
    // },
    // closeAcceptDelivery () {
    //   this.visibleAcceptDelivery = false
    //   this.getById()
    // },
    goToBack () {
      this.$router.push({ name: 'voucher_management' })
    },
    downloadFile (record) {
      const fileType = record.fileName.substr(record.fileName.lastIndexOf('.'))
      if (fileType === '.pdf') {
        // bật tab review pdf
        this.loading = true
        getDetailFile({ documentId: record.id }).then(rs => {
          if (rs) {
            const fileName = record.fileName
            const data = this.base64toBlob(rs, fileName)
            var file = new Blob([data], { type: 'application/pdf' })
            var fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          }
        }).catch(err => {
          const msg = this.handleApiError(err)
          this.$error({ content: msg })
        }).finally(res => {
          this.loading = false
        })
      } else if (fileType === '.png' || fileType === '.jpg') {
        // bật tab review pdf
        this.loading = true
        getDetailFile({ documentId: record.id }).then(rs => {
          if (rs) {
            const fileName = record.fileName
            const data = this.base64toBlob(rs, fileName)
            var file = new Blob([data], { type: 'image/png' })
            var fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          }
        }).catch(err => {
          const msg = this.handleApiError(err)
          this.$error({ content: msg })
        }).finally(res => {
          this.loading = false
        })
      } else {
        // Tải xuống
        this.loading = true
        getDetailFile({ documentId: record.id }).then(rs => {
          if (rs) {
            const fileName = record.fileName
            const data = this.base64toBlob(rs, fileName)
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(rs, fileName)
            } else {
              const downloadLink = window.document.createElement('a')
              downloadLink.href = window.URL.createObjectURL(data)
              downloadLink.download = fileName
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }
          }
        }).catch(err => {
          const msg = this.handleApiError(err)
          this.$error({ content: msg })
        }).finally(res => {
          this.loading = false
        })
      }
    },
    base64toBlob (base64Data, contentType) {
      contentType = contentType || ''
      var sliceSize = 1024
      var byteCharacters = atob(base64Data)
      var bytesLength = byteCharacters.length
      var slicesCount = Math.ceil(bytesLength / sliceSize)
      var byteArrays = new Array(slicesCount)

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize
        var end = Math.min(begin + sliceSize, bytesLength)

        var bytes = new Array(end - begin)
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0)
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes)
      }
      return new Blob(byteArrays, { type: contentType })
    },
    deleteRecord (record) {
      this.$confirm({
        title: 'Bạn muốn xóa kiện hàng này?',
        okText: 'Có',
        okType: 'primary',
        cancelText: 'Không',
        onOk: () => {
          if (record.id) {
            this.deleteGL(record.id)
          }
        },
        onCancel () {
        }
      })
    },
    deleteGL (id) {
      const $this = this
      this.loading = true
      deleteVoucherDetail(id)
        .then(rs => {
          $this.getById()
          this.$success({ content: 'Xóa kiện hàng thành công' })
        })
        .catch(err => {
          const msg = this.handleApiError(err)
          this.$notification.error({
            message: '',
            description: msg,
            duration: 5
          })
        }).finally(res => {
          this.loading = false
        })
    },
    deleteRecordDocument (record) {
      this.$confirm({
        title: 'Bạn muốn xóa tài liệu này?',
        okText: 'Có',
        okType: 'primary',
        cancelText: 'Không',
        onOk: () => {
          if (record.id) {
            this.deleteDoc(record.id)
          }
        },
        onCancel () {
        }
      })
    },
    deleteDoc (id) {
      const $this = this
      this.loading = true
      deleteDocumentVoucherDetail(id)
        .then(rs => {
          $this.getById()
          this.$success({ content: 'Xóa tài liệu thành công' })
        })
        .catch(err => {
          const msg = this.handleApiError(err)
          this.$notification.error({
            message: '',
            description: msg,
            duration: 5
          })
        }).finally(res => {
          this.loading = false
        })
    }
  }
}
</script>
