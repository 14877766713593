import { axios } from '@/utils/request'
import api from '@/api/index'

export function searchDeliveryPlan (parameter) {
  return axios({
    url: api.deliveryPlan.search,
    method: 'get',
    params: parameter
  })
}

// thêm mới
export function createDeliveryPlan (parameter) {
  return axios({
    url: api.deliveryPlan.create,
    method: 'post',
    data: parameter
  })
}

// Cập nhật
export function updateDeliveryPlan (parameter) {
  return axios({
    url: api.deliveryPlan.update,
    method: 'post',
    data: parameter
  })
}

// Xóa
export function deleteDeliveryPlan (id) {
  return axios({
    url: '/plan-receive/admin/' + id + '/delete',
    method: 'post'
  })
}

// chi tiết
export function findByIdDeliveryPlan (id) {
  return axios({
    url: '/plan-receive/admin/' + id + '/detail',
    method: 'get'
  })
}
