
export const asyncRouterMap = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../pages/Dashboard/index.vue'),
    meta: {
      authRequired: true,
      head: {
        title: 'Dashboard',
        description: 'Dashboard'
      }
    }
  },
  {
    path: '/admin/login',
    name: 'login',
    component: () => import('../pages/Sys/Login.vue'),
    meta: { authRequired: false }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../pages/Sys/Profile/index.vue'),
    meta: {
      authRequired: true,
      head: {
        title: 'Hồ sơ',
        description: 'Hồ sơ'
      }
    }
  },
  {
    path: '/settings/global-list',
    name: 'settings_global_list',
    component: () => import('../pages/Sys/GlobalList/Index'),
    meta: {
      authRequired: true,
      permission: ['settings_general'],
      head: {
        title: 'Danh mục dùng chung',
        description: 'Danh mục dùng chung'
      }
    }
  },
  {
    path: '/settings/global-params',
    name: 'settings_global_params',
    component: () => import('../pages/Sys/GlobalParam'),
    meta: {
      authRequired: true,
      permission: [ 'settings_global_params' ],
      head: {
        title: 'Tham số hệ thống',
        description: 'Tham số hệ thống'
      }
    }
  },

  // Quản lý kho hàng
  {
    path: '/warehouse-management',
    name: 'warehouse_management',
    component: () => import('../pages/WarehouseManagement/Index'),
    meta: {
      authRequired: true,
      permission: 'warehouse_management',
      head: {
        title: 'Danh mục kho',
        description: 'Danh mục kho'
      }
    }
  },

  // Quản lý máy đọc mã vạch
  {
    path: '/scan-device-management',
    name: 'scan_device_management',
    component: () => import('../pages/ScanDeviceManagement/Index'),
    meta: {
      authRequired: true,
      permission: 'scan_device_management',
      head: {
        title: 'Quản lý máy quét mã vạch',
        description: 'Quản lý máy quét mã vạch'
      }
    }
  },

  // Quản lý Nhập xuất
  {
    path: '/voucher-management',
    name: 'voucher_management',
    component: () => import('../pages/VoucherManagement/Index'),
    meta: {
      authRequired: true,
      permission: 'voucher_management',
      head: {
        title: 'Nhập xuất hàng',
        description: 'Nhập xuất hàng'
      }
    }
  },

  // Chi tiết phiếu xuất nhập kho
  {
    path: '/voucher-management/:id/detail',
    name: 'voucher_management_detail',
    component: () => import('../pages/VoucherManagement/Detail'),
    meta: {
      authRequired: true,
      permission: 'voucher_management_detail',
      head: {
        title: 'Chi tiết phiếu nhập xuất',
        description: 'Chi tiết phiếu nhập xuất'
      }
    }
  },

  // Cập nhật phiếu xuất nhập kho
  {
    path: '/voucher-management/:id/update',
    name: 'voucher_management_update',
    component: () => import('../pages/VoucherManagement/Update.vue'),
    meta: {
      authRequired: true,
      permission: 'voucher_management_update',
      head: {
        title: 'Cập nhật phiếu nhập xuất',
        description: 'Cập nhật phiếu nhập xuất'
      }
    }
  },

  // Quản lý đơn đặt hàng
  {
    path: '/pre-order-management',
    name: 'pre_order_management',
    component: () => import('../pages/PreOrderManagement/Index'),
    meta: {
      authRequired: true,
      permission: 'pre_order_management',
      head: {
        title: 'Quản lý đơn đặt hàng',
        description: 'Quản lý đơn đặt hàng'
      }
    }
  },

  // Chi tiết đơn đặt hàng
  {
    path: '/pre-order-management/detail/:id',
    name: 'pre_order_management.detail',
    component: () => import('../pages/PreOrderManagement/Detail'),
    meta: {
      authRequired: true,
      permission: 'pre_order_management',
      head: {
        title: 'Chi tiết đơn đặt hàng',
        description: 'Chi tiết đơn đặt hàng'
      }
    }
  },

  // Cấu hình
  {
    path: '/config',
    name: 'config',
    component: () => import('../pages/Config/Index'),
    meta: {
      authRequired: true,
      head: {
        title: 'Cấu hình',
        description: 'Cấu hình'
      }
    }
  },
  {
    path: '/config/user-management',
    name: 'user_management',
    component: () => import('../pages/Config/User/Index'),
    meta: {
      authRequired: true,
      permission: 'user_management',
      head: {
        title: 'Quản lý tài khoản',
        description: 'Quản lý tài khoản'
      }
    }
  },
  // Thêm mới tài khoản
  {
    path: '/config/user-management/create',
    name: 'user_management.create',
    component: () => import('../pages/Config/User/Create'),
    meta: {
      authRequired: true,
      permission: 'user_management_insert',
      head: {
        title: 'Thêm mới tài khoản',
        description: 'Thêm mới tài khoản'
      }
    }
  },

  // Cập nhật tài khoản
  {
    path: '/config/user-management/update/:id',
    name: 'user_management.update',
    component: () => import('../pages/Config/User/Update'),
    meta: {
      authRequired: true,
      permission: 'user_management_update',
      head: {
        title: 'Cập nhật tài khoản',
        description: 'Cập nhật tài khoản'
      }
    }
  },

  // quản lý nhóm tài khoản
  {
    path: '/config/role-management',
    name: 'role_management',
    component: () => import('../pages/Config/Roles/Index'),
    meta: {
      authRequired: true,
      permission: 'role_management',
      head: {
        title: 'Quản lý nhóm tài khoản',
        description: 'Quản lý nhóm tài khoản'
      }
    }
  },

  // Báo cáo nhập hàng
  {
    path: '/report/import',
    name: 'rp_import',
    component: () => import('../pages/Report/ImportReport/Index'),
    meta: {
      authRequired: true,
      permission: 'rp_import',
      head: {
        title: 'Báo cáo nhập hàng',
        description: 'Báo cáo nhập hàng'
      }
    }
  },

  // Báo cáo nhập hàng theo biển số xe
  {
    path: '/report/import-by-plate',
    name: 'rp_import_by_plate',
    component: () => import('../pages/Report/ImportByPlateReport/Index'),
    meta: {
      authRequired: true,
      permission: 'rp_import_by_plate',
      head: {
        title: 'Báo cáo nhập hàng theo xe',
        description: 'Báo cáo nhập hàng theo xe'
      }
    }
  },

  // Báo cáo Tổng hợp
  {
    path: '/report/summary',
    name: 'rp_summary',
    component: () => import('../pages/Report/SummaryReport/Index'),
    meta: {
      authRequired: true,
      permission: 'rp_summary',
      head: {
        title: 'Báo cáo tổng hợp',
        description: 'Báo cáo tổng hợp'
      }
    }
  },

  // Kế hoạch nhận hàng
  {
    path: '/delivery-plan',
    name: 'delivery_plan',
    component: () => import('../pages/DeliveryPlan/Index'),
    meta: {
      authRequired: true,
      permission: 'plan_receive_management',
      head: {
        title: 'Kế hoạch nhận hàng',
        description: 'Kế hoạch nhận hàng'
      }
    }
  }

]

export const constantRouterMap = [
  {
    path: '/admin/login',
    name: 'login',
    component: () => import('../pages/Sys/Login.vue'),
    meta: { authRequired: false }

  }
]
