<template>
  <main-layout>
    <template v-slot:breadcrumb>
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><a href="/">Home</a></a-breadcrumb-item>
        <a-breadcrumb-item :class="'active'">Báo cáo tổng hợp</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <a-form-model
      ref="ruleFilter"
      :model="filters"
      @submit="search"
      layout="vertical">
      <a-collapse v-model="activeSearchKey" expandIconPosition="left" class="collapse-left">
        <a-collapse-panel header="Điều kiện tìm kiếm" key="1">
          <a-card style="width: 100%;border: none" class="search-container">
            <a-row :gutter="16" type="flex" justify="center">
              <a-col :xs="8" :md="8" :lg="8" class="filter-item-container">
                <a-form-model-item
                  prop="preOrderNo"
                  label="Mã đơn đặt hàng"
                  :rules="[]">
                  <a-input
                    placeholder="Mã đơn đặt hàng"
                    v-model="filters.preOrderNo"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :xs="8" :md="8" :lg="8" class="filter-item-container">
                <a-form-model-item
                  prop="fromDate"
                  label="Từ ngày"
                  :rules="[
                    {
                      required: true,
                      message: 'Từ ngày bắt buộc chọn',
                      trigger: 'change'
                    }
                  ]">
                  <a-date-picker
                    allowClear
                    show-time
                    placeholder="DD/MM/YYYY HH:mm"
                    :format="'DD/MM/YYYY HH:mm'"
                    v-model="filters.fromDate"
                    :disabled-date="disabledFromDate">

                  </a-date-picker>
                </a-form-model-item>
              </a-col>
              <a-col :xs="8" :md="8" :lg="8" class="filter-item-container">
                <a-form-model-item
                  prop="toDate"
                  label="Đến ngày"
                  :rules="[
                    {
                      required: true,
                      message: 'Đến ngày bắt buộc chọn',
                      trigger: 'change'
                    }
                  ]">
                  <a-date-picker
                    allowClear
                    show-time
                    placeholder="DD/MM/YYYY HH:mm"
                    :format="'DD/MM/YYYY HH:mm'"
                    v-model="filters.toDate"
                    :disabled-date="disabledToDate">
                  </a-date-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col
                :xs="24"
                :md="24"
                :lg="24"
                class="filter-item-container"
                style="display: flex;flex-wrap: wrap; margin-top: 20px; justify-content: center">
                <a-button class="btn-success uppercase" @click="resetForm" style="margin-left: 10px">
                  Nhập lại
                </a-button>
                <a-button
                  style="margin-left: 10px"
                  :loading="loading"
                  :disabled="loadingPdf || loadingExport"
                  type="primary"
                  class="btn-success uppercase"
                  @click="search">Tìm kiếm
                </a-button>
                <a-button
                  :loading="loadingExport"
                  :disabled="loadingPdf || loading"
                  type="primary"
                  class="btn-success uppercase"
                  style="margin-left: 10px"
                  @click="exportExcel('xlsx')">Xuất Excel
                </a-button>
              </a-col>
            </a-row>
          </a-card>
        </a-collapse-panel>
      </a-collapse>
    </a-form-model>
    <a-collapse v-model="activeResultKey" expandIconPosition="left" style=" margin-top: 8px" class="collapse-left">
      <a-collapse-panel header="Kết quả tìm kiếm" key="1">
        <a-card style="width: 100%; border: none" class="vts-table-container">
          <a-row :gutter="16" type="flex">
            <a-col :span="24">
              <div class="wrapper1">
                <div class="div1"></div>
              </div>
              <a-table
                ref="tb1"
                :columns="columns"
                :data-source="data"
                :rowKey=" (rowKey, index ) => index"
                :pagination="data.length === 0 ? false : pagination"
                :loading="loading"
                :scroll="{ x: '100%' }"
                :locale="{ emptyText: 'Chưa có dữ liệu' }"
                @change="handleTableChange"
                class="ant-table-bordered">
                <template slot="rowIndex" slot-scope="text, record, index">
                  <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                </template>
              </a-table>
            </a-col>
          </a-row>
        </a-card>
      </a-collapse-panel>
    </a-collapse>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import resizeableTitle from '@/utils/resizable-columns'
import TableEmptyText from '@/utils/table-empty-text'
import columns from './columns'
import _merge from 'lodash/merge'
import { exportSummaryReport, searchSummaryReport } from '@/api/Report/summary'
import { commonMethods, authComputed } from '@/store/helpers'
import moment from 'moment'
import pdf from 'vue-pdf'

const ResizeableTitle = resizeableTitle(columns)
export default {
  components: {
    MainLayout,
    pdf
  },
  mixins: [TableEmptyText],
  name: 'ImportReport',
  data () {
    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
    return {
      activeSearchKey: 1,
      activeResultKey: 1,
      data: [],
      expandedRowKeys: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      },
      loading: false,
      columns,
      filters: {
        preOrderNo: '',
        fromDate: moment().subtract(1, 'month'),
        toDate: moment()
      },
      dataPdf: '',
      loadingExport: false,
      loadingPdf: false

    }
  },
  async created () {
  },
  mounted () {
    this.scrollBarOfTable()
  },
  computed: {
    ...authComputed
  },
  methods: {
    ...commonMethods,
    disabledFromDate (fromDate) {
      const toDate = this.filters.toDate
      if (!fromDate || !toDate) {
        return false
      }
      return toDate.valueOf() <= fromDate.valueOf()
    },
    disabledToDate (toDate) {
      const fromDate = this.filters.fromDate
      if (!toDate || !fromDate) {
        return false
      }
      return fromDate.valueOf() >= toDate.valueOf()
    },
    resetForm (e) {
      this.$refs.ruleFilter.resetFields()
      this.search(e)
    },
    handleTableChange (pagination, filters, sorter) {
      this.pagination = pagination
      this.getData()
    },
    search () {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          this.pagination.current = 1
          this.getData()
        }
      })
    },
    getData () {
      this.loading = true
      const params = {
        preOrderNo: this.filters.preOrderNo,
        fromDate: this.filters.fromDate ? moment(this.filters.fromDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
        toDate: this.filters.toDate ? moment(this.filters.toDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : ''
      }
      this.data = []
      searchSummaryReport(params).then(res => {
        this.data = this.convertPropToDisplayDate(res)
        this.pagination = _merge(this.pagination, this.handlePaginationData(res))
        window.localStorage.setItem('rp_summary.filters', JSON.stringify(params))
        this.loading = false
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$notification.error({
          message: '',
          description: msg,
          duration: 5
        })
      }).finally(res => {
        this.loading = false
      })
    },
    exportExcel (type) {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          const params = {
            fileType: type,
            fileName: 'Bao_cao_tong_hop',
            params: {
              preOrderNo: this.filters.preOrderNo,
              fromDate: this.filters.fromDate ? moment(this.filters.fromDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
              toDate: this.filters.toDate ? moment(this.filters.toDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : ''
            },
            reportName: 'rp_summary'
          }
          this.loadingExport = true
          exportSummaryReport(params)
            .then(rs => {
              const date = new Date(moment())
              const fileName = 'Bao_cao_tong_hop_' + moment(date, 'YYYY_MM_DD').format('YYYY_MM_DD') + '.' + type
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(rs, fileName)
              } else {
                const downloadLink = window.document.createElement('a')
                downloadLink.href = window.URL.createObjectURL(rs)
                downloadLink.download = fileName
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
              }
            }).catch(err => {
              const msg = this.handleApiError(err)
              this.$error({ content: msg })
            }).finally(res => {
              this.loadingExport = false
            })
        }
      })
    }

  }
}
</script>
