<template>
  <main-layout>
    <template v-slot:breadcrumb>
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><a href="/">Home</a></a-breadcrumb-item>
        <a-breadcrumb-item :class="'active'">Báo cáo nhập hàng theo xe</a-breadcrumb-item>
      </a-breadcrumb>

    </template>
    <a-form-model
      ref="ruleFilter"
      :model="filters"
      @submit="search"
      layout="vertical">
      <a-collapse v-model="activeSearchKey" expandIconPosition="left" class="collapse-left">
        <a-collapse-panel header="Điều kiện tìm kiếm" key="1">
          <a-card style="width: 100%;border: none" class="search-container">
            <a-row :gutter="16" type="flex" justify="center">
              <a-col :xs="6" :md="6" :lg="6" class="filter-item-container">
                <label class="i-title-input">Biển số xe </label>
                <a-form-model-item
                  prop="plateNumber"
                  :rules="[]">
                  <a-input
                    placeholder="Biển số xe"
                    v-model="filters.plateNumber"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :xs="6" :md="6" :lg="6" class="filter-item-container">
                <label class="i-title-input">Từ ngày <span style="color: red"> *</span></label>
                <a-form-model-item
                  prop="fromDate"
                  :rules="[
                    {
                      required: true,
                      message: 'Từ ngày bắt buộc nhập',
                      trigger: 'change'
                    }
                  ]">
                  <a-date-picker
                    show-time
                    placeholder="DD/MM/YYYY HH:mm"
                    :format="'DD/MM/YYYY HH:mm'"
                    v-model="filters.fromDate"
                    :disabled-date="disabledFromDate"
                  >

                  </a-date-picker>
                </a-form-model-item>
              </a-col>
              <a-col :xs="6" :md="6" :lg="6" class="filter-item-container">
                <label class="i-title-input">Đến ngày <span style="color: red"> *</span></label>
                <a-form-model-item
                  prop="toDate"
                  :rules="[
                    {
                      required: true,
                      message: 'Đến ngày bắt buộc nhập',
                      trigger: 'change'
                    }
                  ]">
                  <a-date-picker
                    show-time
                    placeholder="DD/MM/YYYY HH:mm"
                    :format="'DD/MM/YYYY HH:mm'"
                    v-model="filters.toDate"
                    :disabled-date="disabledToDate"
                  >
                  </a-date-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col
                :xs="24"
                :md="24"
                :lg="24"
                class="filter-item-container"
                style="display: flex;flex-wrap: wrap; margin-top: 20px; justify-content: center">
                <a-button class="btn-success uppercase" @click="resetForm" style="margin-left: 10px">
                  Nhập lại
                </a-button>
                <a-button
                  style="margin-left: 10px"
                  :loading="loading"
                  :disabled="loadingPdf || loadingExport"
                  type="primary"
                  class="btn-success uppercase"
                  @click="search">Tìm kiếm
                </a-button>
                <a-button
                  style="margin-left: 10px"
                  :disabled="loadingPdf || loadingExport"
                  type="primary"
                  class="btn-success uppercase"
                  @click="showDetail">Xem chi tiết
                </a-button>
                <a-button
                  :loading="loadingExport"
                  :disabled="loadingPdf || loading"
                  type="primary"
                  class="btn-success uppercase"
                  style="margin-left: 10px"
                  @click="exportExcel('xlsx')">Xuất Excel
                </a-button>
              </a-col>
            </a-row>
          </a-card>
        </a-collapse-panel>
      </a-collapse>
    </a-form-model>
    <a-collapse v-model="activeResultKey" expandIconPosition="left" style=" margin-top: 8px" class="collapse-left">
      <a-collapse-panel header="Kết quả tìm kiếm" key="1">
        <a-card style="width: 100%; border: none" class="vts-table-container">
          <a-row :gutter="16" type="flex">
            <a-col :span="24">
              <div class="wrapper1">
                <div class="div1"></div>
              </div>
              <a-table
                ref="tb1"
                :columns="columns"
                :data-source="data"
                :rowKey="rowKey"
                :pagination="data.length === 0 ? false : pagination"
                :loading="loading"
                :scroll="{ x: '100%' }"
                :locale="{ emptyText: 'Chưa có dữ liệu' }"
                @change="handleTableChange"
                class="ant-table-bordered"
                :row-selection="rowSelection">
                <template slot="actionTitle">
                  <a-icon type="control" :style="{fontSize: '14px'}"/>
                </template>
                <template slot="rowIndex" slot-scope="text, record, index">
                  <span>{{ getTableRowIndex(pagination.pageSize, pagination.current, index) }} </span>
                </template>
                <template slot="importAt" slot-scope="text, record">
                  <span>{{ record.importAt || '' }} </span>
                </template>
                <template slot="cartonNo" slot-scope="text, record">
                  <span>{{ record.cartonNo || '' }}</span>
                </template>
                <!--                <template slot="operation" slot-scope="text, record">-->
                <!--                  <a-popover v-if="record.voucherId">-->
                <!--                    <template slot="content">-->
                <!--                      <span>Chi tiết</span>-->
                <!--                    </template>-->
                <!--                    <a-icon type="eye" @click="showDetail(record)" style=" color: #086885"></a-icon>-->
                <!--                  </a-popover>-->
                <!--                </template>-->
              </a-table>
            </a-col>
          </a-row>
        </a-card>
      </a-collapse-panel>
    </a-collapse>
    <popup-detail
      v-if="visibleDetail === true"
      :visibleDetail="visibleDetail"
      :listId="listId"
      @closePopup="closeDetail">

    </popup-detail>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import resizeableTitle from '@/utils/resizable-columns'
import TableEmptyText from '@/utils/table-empty-text'
import columns from './columns'
import _merge from 'lodash/merge'
import { commonMethods, authComputed } from '@/store/helpers'
import moment from 'moment'
import pdf from 'vue-pdf'
import PopupDetail from './PopupDetail'
import { exportImportByPlateReport, searchImportByPlateReport } from '@/api/Report/import-by-plate'

const ResizeableTitle = resizeableTitle(columns)

export default {
  components: {
    MainLayout,
    pdf,
    PopupDetail
  },
  mixins: [TableEmptyText],
  name: 'ImportReportByPlate',
  data () {
    this.components = {
      header: {
        cell: ResizeableTitle
      }
    }
    return {
      activeSearchKey: 1,
      activeResultKey: 1,
      data: [],
      expandedRowKeys: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 15,
        pageSizes: 500,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['15', '25', '50'],
        showTotal: (total) => {
          return 'Tổng số dòng ' + total
        }
      },
      loading: false,
      columns,
      filters: {
        page: '',
        size: '',
        plateNumber: '',
        fromDate: moment().startOf('month'),
        toDate: moment().endOf('month')
      },
      dataPdf: '',
      loadingExport: false,
      loadingPdf: false,
      visibleDetail: false,
      listId: [],
      selectedRowKeys: [],
      selectedVoucherIds: []
    }
  },
  async created () {
  },
  mounted () {

  },
  computed: {
    ...authComputed,
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true // Ẩn checkbox "Select All"
      }
    }
  },
  methods: {
    ...commonMethods,
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedVoucherIds = selectedRows.map(item => item.voucherId).filter((value, index, self) => {
        return self.indexOf(value) === index
      })
    },
    rowKey (record) {
      return record.voucherId
    },
    disabledFromDate (fromDate) {
      const toDate = this.filters.toDate
      if (!fromDate || !toDate) {
        return false
      }
      return toDate.valueOf() <= fromDate.valueOf()
    },
    disabledToDate (toDate) {
      const fromDate = this.filters.fromDate
      if (!toDate || !fromDate) {
        return false
      }
      return fromDate.valueOf() >= toDate.valueOf()
    },
    resetForm (e) {
      this.$refs.ruleFilter.resetFields()
      this.search(e)
    },
    handleTableChange (pagination, filters, sorter) {
      this.pagination = pagination
      this.getData()
    },
    search () {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          this.pagination.current = 1
          this.getData()
        }
      })
    },
    getData () {
      this.loading = true
      const params = {
        page: this.filters.page,
        size: this.filters.size,
        fromDate: this.filters.fromDate !== null ? moment(this.filters.fromDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
        toDate: this.filters.toDate !== null ? moment(this.filters.toDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
        plateNumber: this.filters.plateNumber
      }
      this.data = []
      searchImportByPlateReport(params).then(res => {
        this.data = this.convertPropToDisplayDate(res)
        this.data = this.data.map(item => {
          return _merge(item, { status: item.status === '1' })
        })
        this.pagination = _merge(this.pagination, this.handlePaginationData(res))
        window.localStorage.setItem('rp_import_by_plate.filters', JSON.stringify(params))
        this.loading = false
      }).catch(err => {
        const msg = this.handleApiError(err)
        this.$notification.error({
          message: '',
          description: msg,
          duration: 5
        })
      }).finally(res => {
        this.loading = false
      })
    },
    showDetail () {
      this.listId = this.selectedVoucherIds
      if (this.listId.length === 0) {
        this.$notification.error({
          message: 'Bạn chưa chọn đơn hàng '
        })
      } else {
        this.visibleDetail = true
      }
    },
    closeDetail () {
      this.visibleDetail = false
      this.listId = []
    },
    exportExcel (type) {
      this.$refs.ruleFilter.validate(valid => {
        if (valid) {
          const params = {
            fileType: type,
            fileName: 'Bao_cao_nhap_hang_theo_xe_' + this.filters.plateNumber,
            params: {
              fromDate: this.filters.fromDate !== null ? moment(this.filters.fromDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
              toDate: this.filters.toDate !== null ? moment(this.filters.toDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '',
              plateNumber: this.filters.plateNumber
            },
            reportName: 'rp_import_by_plate'
          }
          this.loadingExport = true
          exportImportByPlateReport(params)
            .then(rs => {
              const date = new Date(moment())
              const fileName = 'Bao_cao_nhap_hang_theo_xe_' + moment(date, 'YYYY_MM_DD').format('YYYY_MM_DD') + '.' + type
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(rs, fileName)
              } else {
                const downloadLink = window.document.createElement('a')
                downloadLink.href = window.URL.createObjectURL(rs)
                downloadLink.download = fileName
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
              }
            }).catch(err => {
              const msg = this.handleApiError(err)
              this.$error({ content: msg })
            }).finally(res => {
              this.loadingExport = false
            })
        }
      })
    }

  }
}
</script>
<style lang="less">
.ant-table-thead .ant-checkbox-wrapper {
  display: none;
}
</style>
